<template>
  <div
    v-if="
      !isUserLoggedOut &&
      $router.currentRoute.value.name !== routesNames.slideShow
    "
    class="announcement bg-ypony-gradient-x d-flex align-item-center justify-content-center"
  >
    <span>Refer your friends with 2 clicks!</span>
    <router-link
      :to="{
        name: routesNames.accounts,
        params: {
          account_id: currentUser.account_id,
        },
        query: {
          selected_tab: 2,
        },
      }"
    >
      <button>Start here</button>
    </router-link>
  </div>
  <header
    :class="[
      'home container',
      $router.currentRoute.value.name === routesNames.login ||
      $router.currentRoute.value.name === routesNames.passwordReset ||
      $router.currentRoute.value.name === routesNames.register
        ? 'login'
        : '',
      $router.currentRoute.value.name === routesNames.slideShow
        ? 'slideShow'
        : '',
    ]"
  >
    <div class="brand">
      <router-link to="/" class="d-flex flex-column">
        <img id="logo" alt="Y.Pony logo" :src="logoColor2x" data-e2e="logo" />
      </router-link>
    </div>

    <div id="sidemenu">
      <div class="d-flex">
        <div
          v-if="
            !isUserLoggedOut &&
            current_account.attributes.company &&
            current_account.attributes.avatar &&
            current_account.attributes.address
          "
        >
          <router-link
            :to="{
              name: routesNames.addOrEditStyle,
              params: {
                account_id: currentUser.account_id,
              },
            }"
          >
            <button class="sidemenu__btn">
              <img
                id="add"
                title="Add new image"
                src="/src/assets/icon-add.svg"
                alt="Side menu btn"
              />
            </button>
          </router-link>
        </div>
        <OnClickOutside @trigger="hideMenu">
          <button
            class="sidemenu__btn"
            :class="{ active: navOpen }"
            title="Menu"
            data-e2e="menu-button"
            @click="navOpen = !navOpen"
          >
            <span class="top" />
            <span class="mid" />
            <span class="bottom" />
          </button>
        </OnClickOutside>
      </div>
      <transition name="translateX">
        <nav v-show="navOpen" id="menu" class>
          <div class="nav--main">
            <h2 class="align-left h-1 font-wht mb-3">Menu</h2>
            <div class="">
              <ul class="">
                <li v-for="item in currentMenu" :key="item.name">
                  <router-link
                    :key="item.name"
                    :to="{ name: item.to, params: item.params }"
                    :class="[
                      $router.currentRoute.value.name === item.to
                        ? 'text-main'
                        : 'text-secondary',
                      'btn btn-secondary my-2',
                    ]"
                    :data-e2e="`${item.name.replace(/\s/g, '')}-button`"
                    :aria-current="
                      $router.currentRoute.value.name === item.to ? true : ''
                    "
                    @click.prevent="navOpen = false"
                  >
                    <span>
                      {{ item.name }}
                    </span>
                  </router-link>
                </li>
              </ul>
              <ul v-if="!isUserLoggedOut">
                <li v-if="isAdmin(currentUser.abilities)">
                  <router-link
                    :key="routesNames.admin"
                    :to="{ name: routesNames.admin }"
                    :class="[
                      $router.currentRoute.value.name === routesNames.admin
                        ? 'text-main'
                        : 'text-secondary',
                      'btn btn-secondary my-2',
                    ]"
                    :aria-current="
                      $router.currentRoute.value.name === routesNames.admin
                        ? true
                        : ''
                    "
                    @click.prevent="navOpen = false"
                  >
                    <span>
                      {{ routesNames.admin }}
                    </span>
                  </router-link>
                </li>
                <li>
                  <router-link
                    :key="routesNames.logout"
                    :to="{ name: routesNames.logout }"
                    :class="[
                      $router.currentRoute.value.name === routesNames.logout
                        ? 'text-main'
                        : 'text-secondary',
                      'btn btn-secondary my-2',
                    ]"
                    :aria-current="
                      $router.currentRoute.value.name === routesNames.logout
                        ? true
                        : ''
                    "
                    @click.prevent="navOpen = false"
                  >
                    <span>
                      {{ routesNames.logout }}
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <small class="nav--about">
              <ul class="">
                <li>
                  <router-link
                    :key="routesNames.imprint"
                    :to="{ name: routesNames.imprint }"
                    :aria-current="routesNames.imprint"
                    :class="[
                      $router.currentRoute.value.name === routesNames.imprint
                        ? 'font-wht'
                        : '',
                    ]"
                    @click.prevent="navOpen = false"
                  >
                    {{ routesNames.imprint }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :key="routesNames.dataPrivacy"
                    :to="{ name: routesNames.dataPrivacy }"
                    :aria-current="routesNames.dataPrivacy"
                    :class="[
                      $router.currentRoute.value.name ===
                      routesNames.dataPrivacy
                        ? 'font-wht'
                        : '',
                    ]"
                    @click.prevent="navOpen = false"
                  >
                    {{ routesNames.dataPrivacy }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :key="routesNames.cookies"
                    :to="{ name: routesNames.cookies }"
                    :aria-current="routesNames.cookies"
                    :class="[
                      $router.currentRoute.value.name === routesNames.cookies
                        ? 'font-wht'
                        : '',
                    ]"
                    @click.prevent="navOpen = false"
                  >
                    {{ routesNames.cookies }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :key="routesNames.legal"
                    :to="{ name: routesNames.legal }"
                    :aria-current="routesNames.legal"
                    :class="[
                      $router.currentRoute.value.name === routesNames.legal
                        ? 'font-wht'
                        : '',
                    ]"
                    @click.prevent="navOpen = false"
                  >
                    {{ routesNames.legal }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    :key="routesNames.copyright"
                    :to="{ name: routesNames.copyright }"
                    :aria-current="routesNames.copyright"
                    :class="[
                      $router.currentRoute.value.name === routesNames.copyright
                        ? 'font-wht'
                        : '',
                    ]"
                    @click.prevent="navOpen = false"
                  >
                    {{ routesNames.copyright }}
                  </router-link>
                </li>
              </ul>
            </small>
            <div class="nav--footer">
              <span class="font-heading"
                >Build with <span class="font-base">♥︎</span> in Hamburg</span
              >
            </div>
          </div>
        </nav>
      </transition>
      <transition name="backdrop">
        <div v-show="navOpen" class="backdrop" />
      </transition>
    </div>
  </header>
  <Popup />
  <CookieBanner v-if="showBanner" @hide-banner="showBanner = false" />
  <router-view :key="$route.fullPath" />
</template>

<script lang="ts">
  import { useAuthTokenStore } from '@/store/authToken'
import { OnClickOutside } from '@vueuse/components'
import { useFavicon } from '@vueuse/core'
import { mapState, mapStores } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { CookieBanner } from './components'
import Popup from './components/Popup.vue'
import {
  favicon,
  isAdmin,
  logoColor2x,
  toTitleCase
} from './helpers'
import { routesNames } from './router'
import { useCookieBannerStore } from './store/CookieBannerStore'
import { useAccountStore } from './store/accounts'

  export default defineComponent({
    name: 'App',
    components: { OnClickOutside, Popup, CookieBanner },

    setup() {
      useFavicon(favicon)
      const toast = useToast()
      const navOpen = ref(false)
      const showBanner = ref(useCookieBannerStore().getOptIn.opt_in_capturing)

      return { toast, navOpen, routesNames, showBanner, logoColor2x }
    },
    expose: ['isUserLoggedOut'],
    data() {
      return {}
    },
    computed: {
      ...mapState(useAuthTokenStore, {
        currentUser: 'currentUser',
        isUserLoggedOut: 'isUserLoggedOut',
      }),
      ...mapState(useAccountStore, {
        current_account: 'current_account',
      }),
      ...mapStores(useAuthTokenStore, useAccountStore),
      currentMenu() {
        return this.isUserLoggedOut
          ? [
              { name: 'Home', to: routesNames.home, params: {} },
              { name: 'LogIn', to: routesNames.login, params: {} },
              { name: 'Register', to: routesNames.register, params: {} },
            ]
          : [
              { name: 'Home', to: 'home', params: {} },
              {
                name: 'Portfolio',
                to: routesNames.portfolios,
                params: {
                  account_id: this.currentUser.account_id,
                },
              },
              {
                name: 'Account',
                to: routesNames.accounts,
                params: { account_id: this.currentUser.account_id },
              },
              {
                name: 'Slide Show',
                to: routesNames.slideShow,
                params: {
                  fullname_account_id: `${this.currentUser.fullname.replace(
                    /\s/g,
                    ''
                  )}-${this.currentUser.account_id}`,
                },
              },
              {
                name: 'QR Code',
                to: routesNames.qrCode,
                params: { account_id: this.currentUser.account_id },
              },
            ]
      },
    },
    watch: {
      $route(to) {
        document.title =
          to.name === routesNames.home ? 'Y.Pony' : toTitleCase(to.name)
        if (to.name === routesNames.slideShow) {
          this.showBanner = false
          useCookieBannerStore().UPDATE_OPT_IN({ opt_in: true })
        }
      },
    },
    methods: {
      hideMenu() {
        this.navOpen = false
      },
      isAdmin(abilities: string[]) {
        return isAdmin(abilities)
      },
    },
  })
</script>

<style lang="scss">
  #app {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    min-height: 100vh;
  }

  #sidemenu {
    nav {
      left: 0;
      z-index: 99;
      position: fixed;
      width: calc(100vw - 5em);
      background-color: rgba(249, 148, 172, 0.95); // left: -100%;
      transition: all 0.2s ease-in-out;
      top: 0;
      box-sizing: border-box;
      min-height: 100vh;
      max-width: 400px;
      overflow-y: scroll;
      overflow-x: hidden;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
      }

      .nav--main {
        padding: 2em 1em;
      }

      .nav--about {
        padding: 1em;
        background-color: rgba(217, 118, 142, 0.8);
        display: block;

        a {
          z-index: 999;
          color: rgba(255, 255, 255, 0.9);
        }

        ul {
          display: flex;
          gap: 0.25em 0.75em;
          text-wrap: nowrap;
          flex-flow: wrap;
          justify-content: center;
          flex-direction: row;
          margin: 0;
        }
      }

      .nav--footer {
        color: #fff;
        padding: 1em;
        background-color: rgba(132, 59, 76, 0.8);
      }
    }
    .sidemenu {
      &__btn {
        display: block;
        width: 50px;
        height: 50px;
        background: none;
        border: none;
        position: relative;
        z-index: 100;
        appearance: none;
        cursor: pointer;
        outline: none;
        opacity: 0.8;

        span {
          display: block;
          width: 24px;
          height: 2px;
          margin: auto;
          background: rgba(249, 149, 172, 1);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          transition: all 0.4s ease;
          &.top {
            transform: translateY(-8px);
          }
          &.bottom {
            transform: translateY(8px);
          }
        }
        &.active {
          .top {
            transform: rotate(-45deg);
          }
          .mid {
            transform: translateX(-0px) rotate(360deg);
            opacity: 0;
          }
          .bottom {
            transform: rotate(45deg);
          }
        }
      }
      &__wrapper {
        padding-top: 50px;
      }
      &__list {
        list-style: none;
        padding: 50px 0 0 0;
        margin: 0;
      }
      &__item {
        a {
          text-decoration: none;
          line-height: 1.6em;
          font-size: 1.6em;
          padding: 0.5em;
          display: block;
          color: white;
          transition: 0.4s ease;

          &:hover {
            background: lightgrey;
            color: dimgrey;
          }
        }
      }
    }
  }

  .translateX-enter-active,
  .translateX-leave-active {
    transform: translateX(-400px);
    opacity: 1;
    // transform: translateX(calc(-100vw - 1em));
  }

  .translateX-enter,
  .translateX-enter-from,
  .translateX-leave-to {
    opacity: 0;
  }

  .backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.6s ease;
    z-index: 98;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .backdrop-enter-active,
  .backdrop-leave-active {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
  }

  .backdrop-enter-from,
  .backdrop-leave-to {
    opacity: 0;
  }
</style>
